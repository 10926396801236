<template>
  <div class="recipes">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova receita</span>
    </button>
    <b-table
      :data="allRecipes"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="name"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{
          props.row.type === 'Manual' ? props.row.name : '#' + props.row.id
        }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{
            props.row.type === 'Manual'
              ? props.row.name
              : '#' + props.row.service_id
          }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="value"
        label="Valor"
        :searchable="true"
        sortable
        v-slot="props"
        >R$ {{ parseFloat(props.row.value).toFixed(2) }}</b-table-column
      >

      <b-table-column
        field="type"
        label="Tipo de receita"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.type }}</b-table-column
      >

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ parseFloat(props.row.value).toFixed(2) }}</small>
                <br />
                <strong>Descrição:</strong>
                <small>{{
                  props.row.description ? props.row.description : 'N/A'
                }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Data *">
              <b-datepicker
                ref="datepicker"
                inline
                v-model="model.date"
                placeholder="Selecione uma data"
              ></b-datepicker>
            </b-field>
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field>
            <b-field label="Descrição">
              <b-input
                maxlength="200"
                type="textarea"
                v-model="model.description"
              ></b-input>
            </b-field>
            <b-field label="Categoria">
              <b-select
                placeholder="Selecione uma categoria"
                required
                expanded
                v-model="financialCategoryId"
                @input="changeCategory"
              >
                <option
                  v-for="(item, index) in allFinancialCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Subcategoria">
              <b-select
                placeholder="Selecione uma subcategoria"
                required
                v-model="model.financial_subcategory_id"
                expanded
              >
                <option
                  v-for="(item, index) in allFilterFinancialSubCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/recipes/store/service'
import Recipe from '../models/recipe'

export default {
  name: 'Recipes',
  data() {
    return {
      model: Recipe.model,
      showDetailIcon: false,
      isCardModalActive: false,
      allFilterFinancialSubCategories: [],
      financialCategoryId: null,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('recipes', ['allRecipes']),
    ...mapState('financialCategories', ['allFinancialCategories']),
    ...mapState('financialSubCategories', ['allFinancialSubCategories']),
  },
  methods: {
    ...mapActions('recipes', ['getAllRecipes']),
    ...mapActions('financialCategories', ['getAllFinancialCategories']),
    ...mapActions('financialSubCategories', ['getAllFinancialSubCategories']),
    async init() {
      await this.getAllRecipes()
      await this.getAllFinancialCategories()
      await this.getAllFinancialSubCategories()

      console.log('all recipes ', this.allRecipes)
      console.log('all cat ', this.allFinancialCategories)
      console.log('all sub ', this.allFinancialSubCategories)
    },
    changeCategory(category) {
      this.allFilterFinancialSubCategories =
        this.allFinancialSubCategories.filter(
          (x) => x.financial_category_id === category
        )
      console.log(this.allFilterFinancialSubCategories)
    },
    onEdit(item) {
      item.value = parseFloat(item.value).toFixed(2)
      item.date = new Date(item.date)

      if (item.subcategory) {
        this.financialCategoryId = item.subcategory.category.id
        item.financial_subcategory_id = item.subcategory.id
        this.changeCategory(this.financialCategoryId)
      }

      this.model = { ...item }
    },
    async validation() {
      let isValid = true

      await Recipe.required.forEach((x) => {
        if (!this.model[x]) {
          isValid = false
        }
      })

      return isValid
    },
    async save() {
      const recipe = { ...this.model }

      recipe.value = recipe.value.replace('R$ ', '')
      recipe.value = recipe.value.replace(',', '')
      recipe.date = this.$moment(recipe.date).format('YYYY-MM-DD')

      if (!(await this.validation()))
        return this.$error('É necessário preencher todos os campos com *')

      console.log(recipe)

      try {
        recipe.id
          ? await service.updateRecipe(recipe)
          : await service.saveRecipe(recipe)
        await this.$success('Receita')
        location.reload(true)
      } catch (error) {
        console.log(error)
        this.$error(error.response.data.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a receita?')

        if (result.isConfirmed) {
          await service.deleteRecipe(id)

          await this.$delete('Receita')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
